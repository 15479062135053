import { SearchInput, SelectInput, TextInput } from "react-admin";
import { MarketReferenceInput } from "../../../market";
import { PRODUCT } from "../constants";
import { WebsiteReferenceInput } from "../../../website";
import { YoutubeReferenceInput } from "../../../youtube/inputs";

export const filters = [
  <SearchInput source="q" alwaysOn />,
  <WebsiteReferenceInput
    source={"website"}
    label={"Website"}
    helperText={""}
    emptyValue={"All"}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <YoutubeReferenceInput
    source={"youtube"}
    label={"Youtube"}
    helperText={""}
    emptyValue={"All"}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <MarketReferenceInput
    source={"market"}
    label={"Market"}
    helperText={""}
    emptyValue={"All"}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <SelectInput
    label="Product"
    source="deal_product"
    choices={PRODUCT}
    emptyValue="All"
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <TextInput label="Campaign" source="campaign" resettable alwaysOn />,
  <SelectInput
    label="Is Click Tracked"
    source="is_click_tracked"
    choices={[
      { id: "true", name: "✅ Yes" },
      { id: "false", name: "❌ No" },
    ]}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
];

export default filters;
